import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Navbar from './Navbar';
import Couverture from './Couverture';
import Services from './Services';
import Footer from '../footer/Foooter';


const Home = () => {
    return (
        <>
        <section className='header'>
              <Navbar/>
            <div className="text-box">
                <h1>L'aimer, le chouchouter, l'assurer.</h1>
                <p>Petcare vous propose une panoplie de garanties à des tarifs compétitifs <br></br>
                    pour mieux prendre soin de votre petit compagnon
                </p>
                <Link to="" class="hero-btn">Devis en ligne</Link>
            </div>
        </section>
        <Couverture/>
        <Services/>
       <Footer/>
        </>
    
    )
}

export default Home
