import React from 'react'
import logo from '../images/logo.png'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
const Navbar = () => {
  return (
    <nav>
    <Link to="#"><img src={logo} alt=""/></Link>
    <div className="nav-links" id="navLinks">
        <ul>
            <li> <Link to="#" >A PROPOS</Link> </li>
            <li> <Link to="#">COUVERTURES</Link> </li>
            <li> <Link to="#">SERVICES</Link> </li>
            <li> <Link to="#" className='btn'>Mon espace</Link> </li>
        </ul>
    </div>

 </nav>
  )
}

export default Navbar
