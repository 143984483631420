import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import petcare from '../images/petcare.png'
import facebook from '../images/facebook.png'
import twitter from '../images/twitter.png'
import linkedin from '../images/linkedin.png'
import youtube from '../images/youtube.png'
import phone from '../images/phone.png'
const Footer = () => {
  return (
    


    <footer className='text-white pt-2 pb-4 pr-5 footer'>
       <div className='container text-center text-md-left'>
       <div className='row text-center text-md-left '>
       <div className='col-md-4  mt-3 test'>
       <img src={petcare} alt="" className='img' style={{ width:70, marginTop:0}}/>
       <p>L'aimer, le chouchouter, l'assurer. </p>
       <div className='images'>
           <img src={facebook} alt=""/>
           <img src={twitter} alt=""/>
           <img src={linkedin} alt=""/>
           <img src={youtube} alt=""/>
           <img src={phone} alt=""/>
       </div>
       </div>
       <div className=' col-md-3 mt-4'>
       <h5>A Propos</h5> 
       </div>
       <div className=' col-md-3 mt-4'>
      <h5>Couvertures</h5>
      <p>Intervention chirurgicale</p>
      <p>Consultations vétérinaires</p>
      <p className='vaccin'> Vaccins</p>
      <p>Analyses Et Examens Radiologiques</p>
       </div>
       <div className=' col-md-3 mx-auto mt-4'>
       <h5>Resources</h5> 
       <p> Blog</p>
       <p>Vidéos</p>
       <p>FAQs</p>
       </div>
       </div>
       </div>
     <div className='row footer2'>
      <div className="col-md-8"> © 2022 Petcare. Tout droits réservés.</div>
      <div className="col-md-2">Politique de confidentialité</div>
      <div className="col-md-2">Conditions d'utilisation</div>
      </div> 
    
    </footer>
   
    
  )
}

export default Footer
