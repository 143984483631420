import React from 'react'
import img1 from '../images/img1.png'
import img2 from '../images/img2.png'
import img3 from '../images/img3.png'
import img4 from '../images/img4.png'
import video from '../../assets/video.mp4'
import 'bootstrap/dist/css/bootstrap.min.css'
const Couverture = () => {
    return (
        <section id="couverture">
        <div className="container">
            <h1 className="text-center">Couvertures</h1>
            <div className="row">
                <div className="col-md-3 image-container">
                  
                         <img src={img1} alt=''/>
                        
                    <div className='floating-div'>Intervention chirurgicale</div>
                   
                </div>
                <div className="col-md-3 image-container">
                  
                    <img src={img2} alt='' />
                    
                    <div className='floating-div'>Consultations vétérinaires</div>
                </div>
                <div className="col-md-3 image-container">
                    
                    <img src={img3} alt=''  />
                    
                    <div className='floating-div'>Analyses et examens radiologiques</div>
                </div>
                <div className="col-md-3 image-container ">
                    
                    <img src={img4} alt=''/>
                    
                    <div className='floating-div'>Vaccins</div>
                </div>
            </div>
        </div>
        <div className="main">
        <video autoPlay muted loop>
        <source src={video} type='video/mp4' />
      </video>
        <div className='content'>
        <h2>Exigence</h2>
            <p>Parce-que le bien être de votre compagnon est <br></br>
                    crucial, Petcare s'engage à ne proposer que les <br></br>
                     meilleures couvertures et soins pour lui<br></br>
                      garantir joie et santé  <br></br>
                </p>
        
        </div>
          
        </div>
  
    </section>
    )
}

export default Couverture
