import React from 'react'
import icon1 from '../images/icon1.png'
import icon2 from '../images/icon2.png'
import icon3 from '../images/icon3.png'
const services = () => {
  return (
    <section id="services">
     <div className='container'>
     <h1 className="text-center">On vous propose</h1>
     
     <div class="row ">
         <div class="services-col mx-auto">
             <img src={icon1} alt=""/>
             <h4>Une prévention</h4>
             <p>Intégrale dans toutes nos formules
           </p>
         </div>
         <div class="services-col mx-auto">
             <img src={icon2} alt=""/>
             <h4>Des vétérinaires </h4>
             <p>expérimentés et dévoués pour répondre à chaque besoin de votre animal 
           </p>
         </div>
         <div class="services-col mx-auto">
             <img src={icon2} alt=""/>
             <h4>Un service</h4>
             <p>d'urgence vétérinaire 24/7 inclus, <br></br>rapide et fiable
           </p>
         </div>
   
        </div>
       
     </div> 
     <div className='feedback mt-0'>
        <div class="container-sm">
          <div class="row">
              <div class="feedback-col mx-auto" style={{ marginTop:300 }}>
                  <h5>SAMIR</h5>
                 <hr></hr> 
                    <div class="rating">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i>
                    </div>
                    <p>
                    Grâce à cette couverture, j'ai pu opérer <br></br> mon chat sans me soucier des frais de <br></br> l'opération
                    </p>
                </div>
                <div class="feedback-col mx-auto" style={{ marginTop:300 }}>
                   <h5>ACHRAF</h5>
                   <hr></hr>
                      <div class="rating">
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star-o"></i>
                      </div>
                     <p>Je ne me fais plus de soucis pour mon cher <br/> toutou avec Petcare  </p>
                  </div>
                  <div class="feedback-col mx-auto" style={{ marginTop:300 }}>
                   <h5>KAWTAR</h5>
                   <hr></hr>
                      <div class="rating">
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star-o"></i>
                      </div>
                      <p>Je recommande fortement !!</p>
                  </div>
          </div>
      </div>
       </div>
    </section>
  )
}

export default services
