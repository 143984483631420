import './App.css';
// import './components/homePage/HomeStyle.css'

import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import Home from './components/homePage/Home';



function App() {
  return (
 
    <Router>
    <div className="App">
    <Switch>
      <Route exact path='/'><Home/></Route>
     
    </Switch>
      
    </div>
    </Router>
  );
}

export default App;
